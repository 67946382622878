import { ActionTree } from 'vuex'
import { BuilderState } from './types'
import { RootState } from '../../types'
import api from '@/lib/calendesk-js-library/api/api'
import Page from '@/calendesk/models/DTO/Response/Page'
import Draft from '@/calendesk/models/DTO/Response/Draft'
import Section from '@/calendesk/models/DTO/Response/Section'
import { BusinessType, MoveDirection, PageType, PreviewSizeType, SectionType } from '@/calendesk/models/BuilderTypes'
import DraftStateController, { DRAFT_VERSION } from '@/calendesk/controllers/DraftStateController'
import { calculateMovingSectionPosition, calculatePositionForEmptySection } from '@/calendesk/tools/calculatePosition'
import SectionToMove from '@/calendesk/models/SectionToMove'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { ChangeStep, ChangeStepType } from '@/calendesk/models/ChangeStep'
import { RestoredStep, RestoreType } from '@/calendesk/models/RestoredStep'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import { plainToClass } from 'class-transformer'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'
import DraftWithPage from '@/calendesk/models/DraftWithPage'
import { SectionImagePayload } from '@/calendesk/models/SectionImagePayload'
import SectionToUpdate from '@/calendesk/models/SectionToUpdate'
import { assetsSearchKey } from '@/calendesk/models/AssetsSearchKey'
import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'
import SectionToDuplicate from '@/calendesk/models/SectionToDuplicate'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'

export const actions: ActionTree<BuilderState, RootState> = {
  addChangeStep ({ commit }, changeStep: ChangeStep) {
    commit('ADD_TO_STEPS', changeStep)
  },
  clearFutureSteps ({ commit }) {
    commit('CLEAR_FUTURE_STEPS')
  },
  clearAllSteps ({ commit }) {
    commit('CLEAR_ALL_STEPS')
  },
  restoreLastStep ({ dispatch }): Promise<void> {
    return new Promise((resolve, reject) => {
      pushEvent('wbUndoLastStep')

      DraftStateController.getInstance().restoreLastStep().then((restoredStep: RestoredStep) => {
        dispatch('handleStepChange', restoredStep)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  restoreNextStep ({ dispatch }): Promise<void> {
    return new Promise((resolve, reject) => {
      pushEvent('wbRedoLastStep')

      DraftStateController.getInstance().restoreNextStep().then((restoredStep: RestoredStep) => {
        dispatch('handleStepChange', restoredStep)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  handleStepChange ({ commit, state }, restoredStep: RestoredStep) {
    const draft = restoredStep.type === RestoreType.UNDO ? restoredStep.changeStep.oldDraft : restoredStep.changeStep.newDraft
    commit('SET_DRAFT', draft)

    const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === state.currentPage?.id)

    if (currentPage) {
      const selectedSection: Section | undefined = currentPage.sections?.find((_: Section) => _.id === state.selectedSection?.id)

      if (selectedSection) {
        commit('SET_SELECTED_SECTION', selectedSection)
      }

      commit('SET_CURRENT_PAGE', currentPage)
    } else {
      commit('SET_CURRENT_PAGE', draft.pages.find((page: Page) => page.type === PageType.MAIN_PAGE))
    }

    if (state.selectedExternalConfiguration !== null) {
      let selectedExternalConfiguration: Record<string, any> | undefined
      for (const key in draft.configuration) {
        if (key.includes('_configuration')) {
          if (draft.configuration[key].id === state.selectedExternalConfiguration.id) {
            selectedExternalConfiguration = draft.configuration[key]
          }
        }
      }

      if (selectedExternalConfiguration) {
        commit('SET_SELECTED_EXTERNAL_CONFIGURATION', selectedExternalConfiguration)
      }
    }
  },
  fetchDraft ({ dispatch, commit }): Promise<Draft> {
    return new Promise((resolve, reject) => {
      const draftId = localStorage.getItem('draft_id')
      api.getWb2Draft(draftId).then(({ data }) => {
        let draft: Draft
        if (data.configuration.version !== DRAFT_VERSION) {
          pushEvent('wbUpdateDraft')

          draft = plainToClass(Draft, DraftStateController.getInstance().checkIfPlainDraftIsMissingAnyConfiguration(data))
          DraftStateController.getInstance().updateDraft(draft)
        } else {
          draft = plainToClass(Draft, data)
        }
        commit('SET_DRAFT', draft)
        pushEvent('wbSetDraft', {
          draft_id: draft.id,
          draft_uuid: draft.uuid
        })

        const homePage = draft.pages.find((page: Page) => page.type === PageType.MAIN_PAGE)
        commit('SET_CURRENT_PAGE', homePage)
        commit('SET_BUSINESS_TYPE', draft.configuration.business_type_id)

        commit('SET_WEBSITE_STYLE_MODEL', WebsiteStyleModel.getModelForDraft(draft))

        dispatch('assets/setInitialSearchText', assetsSearchKey[draft.configuration.business_type_id], { root: true })
        dispatch('category/fetchCategoryAndServices', [], { root: true })
        dispatch('employee/fetch', [], { root: true })
        dispatch('simpleStore/fetch', { limit: 9999 }, { root: true })
        dispatch('subscription/fetch', { limit: 9999 }, { root: true })
        dispatch('domain/fetch', { limit: 9999 }, { root: true })

        resolve(draft)
      }).catch(error => {
        reject(error)
      })
    })
  },
  publishDraft ({ state }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (state.draft !== null) {
        pushEvent('wbPublishDraft')

        api.publishWb2Draft(state.draft.id).then(() => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      } else {
        reject(new Error('Draft missing!'))
      }
    })
  },
  updateDraftConfigurationProperty ({ state, commit }, property: Record<string, any>): Promise<void> {
    return new Promise((resolve) => {
      const oldDraft = cloneClassObject(state.draft) as Draft
      const draft = cloneClassObject(state.draft) as Draft

      draft.configuration = {
        ...draft.configuration,
        ...property
      }

      commit('SET_DRAFT', draft)
      const changeStep = new ChangeStep(cloneClassObject(draft), oldDraft, ChangeStepType.UPDATE_DRAFT, null, null)
      DraftStateController.getInstance().updateDraftDebounce(draft, changeStep)

      resolve()
    })
  },
  updateDraftExternalConfigurationProperty ({ state, commit }, property: Record<string, any>): Promise<void> {
    return new Promise((resolve) => {
      const oldDraft = cloneClassObject(state.draft) as Draft
      const draft = cloneClassObject(state.draft) as Draft

      let selectedExternalConfiguration: Record<string, any> | undefined

      for (const key in draft.configuration) {
        if (key.includes('_configuration')) {
          if (draft.configuration[key].id === state.selectedExternalConfiguration?.id) {
            draft.configuration[key] = {
              ...draft.configuration[key],
              ...property
            }
            selectedExternalConfiguration = draft.configuration[key]
          }
        }
      }

      commit('SET_DRAFT', draft)
      commit('SET_SELECTED_EXTERNAL_CONFIGURATION', selectedExternalConfiguration)
      const changeStep = new ChangeStep(cloneClassObject(draft), oldDraft, ChangeStepType.UPDATE_DRAFT, null, null)
      DraftStateController.getInstance().updateDraftDebounce(draft, changeStep)

      resolve()
    })
  },
  removePageFromConfigurationInAllSections (context, payload: DraftWithPage): Array<Promise<void>> {
    const promises = Array<Promise<void>>()
    const sections = Array<Section>()
    sections.push(payload.draft.navbar)
    sections.push(payload.draft.footer)
    payload.draft.pages.forEach((page: Page) => {
      page.sections?.forEach((section: Section) => {
        sections.push(section)
      })
    })

    if (sections && sections.length) {
      sections.forEach((section: Section) => {
        const configuration = Object.keys(section.configuration)
        if (configuration && configuration.length) {
          configuration.forEach((key: string) => {
            const pagesConfiguration = key.search('__page_ids__')
            if (pagesConfiguration > 0) {
              const uuids = section.configuration[key]
              if (uuids && Array.isArray(uuids) && uuids.length) {
                if (uuids.includes(payload.page?.uuid)) {
                  section.configuration[key] = uuids.filter((uuid: string) => uuid !== payload.page?.uuid)
                  promises.push(DraftStateController.getInstance().updateSection(payload.draft, section))
                }
              }
            }
          })
        }
      })
    }

    return promises
  },
  updatePage ({ state, commit }, page: Page): Promise<void> {
    return new Promise((resolve) => {
      const draft = cloneClassObject(state.draft) as Draft
      const oldDraft = cloneClassObject(state.draft) as Draft

      const pageUpdated: Page|undefined = draft.pages.find((_: Page) => _.id === page.id)
      const oldPage = cloneClassObject(pageUpdated as Page)

      if (pageUpdated !== undefined) {
        pageUpdated.type = page.type
        pageUpdated.name = page.name
        pageUpdated.route = page.route
        pageUpdated.action = page.action
        pageUpdated.authOnly = page.authOnly
        pageUpdated.configuration = page.configuration
      }

      commit('SET_DRAFT', draft)
      const changeStep = new ChangeStep(cloneClassObject(draft), oldDraft, ChangeStepType.UPDATE_PAGE, cloneClassObject(page), oldPage)

      pushEvent('wbUpdateSubpage')
      DraftStateController.getInstance().updatePage(draft, page, changeStep).then(() => {
        resolve()
      })
    })
  },
  updateSectionConfigurationProperty ({ commit }, sectionToUpdate: SectionToUpdate): Promise<void> {
    return new Promise((resolve) => {
      const oldDraft = cloneClassObject(sectionToUpdate.draft) as Draft
      const oldSection = cloneClassObject(sectionToUpdate.section) as Section

      const draft = cloneClassObject(sectionToUpdate.draft) as Draft

      const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === sectionToUpdate.section.pageId)
      let section: Section | undefined
      const newConfiguration = {
        ...sectionToUpdate.section.configuration,
        ...sectionToUpdate.configuration
      }

      switch (sectionToUpdate.section.type) {
        case SectionType.NAVBAR:
        case SectionType.FOOTER:
          draft[sectionToUpdate.section.type as SectionType.NAVBAR | SectionType.FOOTER].configuration = newConfiguration
          section = draft[sectionToUpdate.section.type as SectionType.NAVBAR | SectionType.FOOTER]
          break
        case SectionType.SECTION:
          if (currentPage !== undefined && currentPage.sections) {
            section = currentPage?.sections.find((_: Section) => _.id === sectionToUpdate.section.id)

            if (currentPage && section) {
              section.configuration = newConfiguration
            }
          }

          break
      }

      commit('SET_DRAFT', draft)

      if (currentPage) {
        commit('SET_CURRENT_PAGE', currentPage)
      }

      commit('SET_SELECTED_SECTION', section)

      if (draft && section) {
        const changeStep = new ChangeStep(cloneClassObject(draft), oldDraft, ChangeStepType.UPDATE_SECTION, cloneClassObject(section), oldSection)
        DraftStateController.getInstance().updateSectionDebounce(draft, section, changeStep)
      }

      resolve()
    })
  },
  createPage ({ commit, state }, page: Page): Promise<void> {
    return new Promise((resolve, reject) => {
      const draft = cloneClassObject(state.draft) as Draft

      if (draft.pages.length) {
        page.configuration.wb_position = draft.pages[draft.pages.length - 1].configuration.wb_position * 2
      } else {
        page.configuration.wb_position = 65535
      }

      commit('SET_DRAFT', draft)

      const changeStep = new ChangeStep(cloneClassObject(draft), cloneClassObject(draft), ChangeStepType.ADD_PAGE, cloneClassObject(page), cloneClassObject(page))
      pushEvent('wbCreateSubpage')

      DraftStateController.getInstance().createPage(draft, page, changeStep)?.then(({ data }) => {
        draft.pages.push(plainToClass(Page, data))
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  removePage ({ commit, state, dispatch }, page: Page): Promise<void> {
    return new Promise((resolve, reject) => {
      const draft = cloneClassObject(state.draft)

      if (draft) {
        draft.pages = draft.pages.filter((_: Page) => _.id !== page.id)

        if (state.currentPage) {
          if (state.currentPage.id === page.id) {
            const filteredPages = draft.pages.filter((_: Page) => _.type !== 'redirect')
            if (Array.isArray(filteredPages) && filteredPages.length) {
              commit('SET_CURRENT_PAGE', filteredPages[0])
            } else {
              commit('SET_CURRENT_PAGE', null)
            }
          }
        }

        const promises = dispatch('removePageFromConfigurationInAllSections', {
          draft,
          page
        })

        commit('SET_DRAFT', draft)
        const removePage = DraftStateController.getInstance().removePage(draft, page)
        pushEvent('wbRemoveSubpage')

        Promise.all([removePage, promises]).then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      }
    })
  },
  removeSection ({ state, commit }, section: Section): Promise<void> {
    return new Promise((resolve, reject) => {
      const draft: Draft = cloneClassObject(state.draft) as Draft
      const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === section.pageId)

      if (currentPage !== undefined && currentPage.sections) {
        currentPage.sections = currentPage.sections.filter((_: Section) => _.id !== section.id)
      }
      pushEvent('wbRemoveSection')

      DraftStateController.getInstance().removeSection(draft, section)?.then(() => {
        commit('SET_DRAFT', draft)
        commit('SET_CURRENT_PAGE', currentPage)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  moveSection ({ state, commit }, sectionToMove: SectionToMove): Promise<void> {
    return new Promise((resolve) => {
      const oldDraft = cloneClassObject(state.draft) as Draft
      const oldSection = cloneClassObject(state.selectedSection) as Section

      const draft: Draft = cloneClassObject(state.draft) as Draft
      const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === sectionToMove.section.pageId)
      if (currentPage !== undefined && currentPage.sections) {
        const newMovedSectionPosition = calculateMovingSectionPosition(currentPage.sections, sectionToMove)

        const movedSection = currentPage.sections.find((section: Section) => section.id === sectionToMove.section.id)
        if (movedSection !== undefined) {
          movedSection.configuration.wb_position = newMovedSectionPosition
          commit('SET_DRAFT', draft)
          commit('SET_CURRENT_PAGE', currentPage)

          const changeStep = new ChangeStep(cloneClassObject(draft), oldDraft, ChangeStepType.UPDATE_SECTION, cloneClassObject(movedSection), oldSection)

          pushEvent('wbMoveSection')
          DraftStateController.getInstance().updateSectionDebounce(draft, movedSection, changeStep)
        }
      }

      resolve()
    })
  },
  deleteEmptySection ({ state, commit, getters }): void {
    const currentPageElements = DraftStateController.getInstance().getCurrentPageElements(state, getters)

    commit('SET_DRAFT', currentPageElements.draft)

    if (currentPageElements.page) {
      commit('SET_CURRENT_PAGE', currentPageElements.page)
    }
  },
  createEmptySection ({ state, commit, getters }, sectionToMove: SectionToMove): void {
    pushEvent('wbCreateEmptySection')

    const currentPageElements = DraftStateController.getInstance().getCurrentPageElements(state, getters)
    let emptySectionPosition = 0

    if (currentPageElements.page !== null && typeof currentPageElements.page !== 'undefined') {
      if (currentPageElements.page.sections) {
        emptySectionPosition = calculatePositionForEmptySection(currentPageElements.page.sections, sectionToMove)
      } else {
        currentPageElements.page.sections = []
      }

      const emptySection = Section.createEmptySection(getters.getCurrentPage.id, emptySectionPosition)
      currentPageElements.page.sections.push(emptySection)

      commit('SET_DRAFT', currentPageElements.draft)
      commit('SET_CURRENT_PAGE', currentPageElements.page)
    }
  },
  createSection ({ state, getters }, newSection: Section): Promise<void> {
    return new Promise((resolve, reject) => {
      const currentPageElements = DraftStateController.getInstance().getCurrentPageElements(state, getters)
      const changeStep = new ChangeStep(currentPageElements.draft, currentPageElements.draft, ChangeStepType.ADD_SECTION, cloneClassObject(newSection), cloneClassObject(newSection))

      pushEvent('wbCreateSection')

      DraftStateController.getInstance().createSection(currentPageElements.draft, newSection, changeStep)?.then(({ data }) => {
        newSection.id = data.id
        newSection.images = plainToClass(SectionImage, data.images as Array<SectionImage>)
        resolve(data)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  duplicateSection ({ state, dispatch, commit, getters }, sectionToDuplicate: SectionToDuplicate): Promise<void> {
    return new Promise((resolve, reject) => {
      const draft: Draft = cloneClassObject(state.draft) as Draft
      const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === getters.getCurrentPage.id)
      const selectedPage: Page | undefined = draft.pages.find((page: Page) => page.id === sectionToDuplicate.page.id)
      const newSection = cloneClassObject(sectionToDuplicate.section)

      let newSectionPosition = parseFloat('1.' + Math.floor(Math.random() * 100000000))
      if (selectedPage !== null && typeof selectedPage !== 'undefined' &&
        currentPage !== null && typeof currentPage !== 'undefined') {
        if (currentPage.id === selectedPage.id && selectedPage.sections) {
          const sectionToMove = new SectionToMove(sectionToDuplicate.section, MoveDirection.DOWN)
          newSectionPosition = calculatePositionForEmptySection(selectedPage.sections, sectionToMove)
        } else {
          // Cloning to a different subpage
          commit('SET_CURRENT_PAGE', selectedPage)
          newSection.pageId = selectedPage.id
        }

        if (!selectedPage.sections) {
          selectedPage.sections = []
        }
      }

      newSection.configuration.wb_position = newSectionPosition
      pushEvent('wbDuplicateSection')

      dispatch('createSection', newSection).then(() => {
        if (selectedPage !== null &&
          typeof selectedPage !== 'undefined' &&
          selectedPage.sections) {
          selectedPage.sections.push(newSection)
        }

        commit('SET_DRAFT', draft)
        commit('SET_CURRENT_PAGE', selectedPage)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  changeEmptyToNewSection ({ state, commit, getters, dispatch }, sectionConfiguration: SectionConfiguration): Promise<void> {
    return new Promise((resolve, reject) => {
      const draft: Draft = cloneClassObject(state.draft) as Draft
      const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === getters.getCurrentPage.id)

      if (currentPage !== undefined && currentPage.sections) {
        const emptySection: Section | undefined = currentPage.sections.find((_: Section) => _.type === SectionType.EMPTY)
        if (emptySection !== undefined) {
          const newSectionConfiguration = cloneClassObject(sectionConfiguration.configuration)
          newSectionConfiguration.wb_position = emptySection.configuration.wb_position

          emptySection.type = sectionConfiguration.type
          emptySection.configuration = newSectionConfiguration
          emptySection.images = SectionImage.createFromDummyImages(sectionConfiguration.images)
          emptySection.pageId = currentPage.id

          dispatch('createSection', emptySection).then(() => {
            commit('SET_DRAFT', draft)
            commit('SET_CURRENT_PAGE', currentPage)
            resolve()
          }).catch((error) => {
            reject(error)
          })
        }
      } else {
        reject(new Error('Sections missing'))
      }
    })
  },
  replaceDraftSection ({ commit, state, dispatch }, sectionConfiguration: SectionConfiguration): Promise<void> {
    return new Promise((resolve, reject) => {
      const draft: Draft = cloneClassObject(state.draft) as Draft
      const sectionType = sectionConfiguration.type === SectionType.NAVBAR ? 'navbar' : 'footer'
      const oldSection = cloneClassObject(draft[sectionType])

      draft[sectionType].configuration = cloneClassObject(sectionConfiguration.configuration)

      if (draft[sectionType].configuration.wb_page_list__page_ids__ && oldSection.configuration.wb_page_list__page_ids__) {
        draft[sectionType].configuration.wb_page_list__page_ids__ = oldSection.configuration.wb_page_list__page_ids__
      }

      draft[sectionType].images = SectionImage.createFromDummyImages(sectionConfiguration.images)

      dispatch('createSection', draft[sectionType]).then(() => {
        DraftStateController.getInstance().removeSection(draft, oldSection)?.then(() => {
          commit('SET_DRAFT', draft)
          resolve()
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateImage ({ commit, state, getters }, payload: SectionImagePayload): Promise<void> {
    return new Promise((resolve, reject) => {
      const draft: Draft = cloneClassObject(state.draft) as Draft
      const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === getters.getCurrentPage.id)
      let section: Section | undefined
      let image: SectionImage | undefined

      if (state.selectedSection?.type === SectionType.NAVBAR || state.selectedSection?.type === SectionType.FOOTER) {
        section = draft[state.selectedSection.type as SectionType.NAVBAR | SectionType.FOOTER]
        image = section?.images.find((image: SectionImage) => image.slug === payload.sectionImage.slug)
        if (image) {
          image.name = payload.selectedGalleryImage.defaultImage.name
          image.userImageId = payload.selectedGalleryImage.defaultImage.userImageId
        }
      } else if (state.selectedSection?.type === SectionType.SECTION) {
        section = currentPage?.sections?.find((section: Section) => section.id === state.selectedSection?.id)
        if (section !== undefined) {
          image = section.images.find((image: SectionImage) => image.slug === payload.sectionImage.slug)
          if (image) {
            image.name = payload.selectedGalleryImage.defaultImage.name
            image.userImageId = payload.selectedGalleryImage.defaultImage.userImageId
          } else {
            if (section.id) {
              image = plainToClass(SectionImage, {
                id: 0,
                slug: payload.sectionImage.slug,
                type: payload.sectionImage.type,
                section_id: section.id,
                user_image_id: payload.selectedGalleryImage.defaultImage.userImageId,
                name: payload.selectedGalleryImage.defaultImage.name
              })
              section.images.push(image)
            }
          }
        }
      }

      commit('SET_DRAFT', draft)
      commit('SET_CURRENT_PAGE', currentPage)

      if (section) {
        DraftStateController.getInstance().updateImage(draft, section, payload).then(({ data }) => {
          if (image) {
            image.id = data.id
          }
          commit('SET_SELECTED_SECTION', section)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      }
    })
  },
  removeImage ({ commit, state, getters }, image: SectionImage): Promise<void> {
    return new Promise((resolve, reject) => {
      const draft: Draft = cloneClassObject(state.draft as Draft)
      const currentPage: Page | undefined = draft.pages.find((page: Page) => page.id === getters.getCurrentPage.id)
      let section: Section | undefined

      if (state.selectedSection?.type === SectionType.NAVBAR || state.selectedSection?.type === SectionType.FOOTER) {
        section = draft[state.selectedSection.type as SectionType.NAVBAR | SectionType.FOOTER]

        if (section) {
          section.images = section?.images.filter((img: SectionImage) => img.slug !== image.slug)
        }
      } else if (state.selectedSection?.type === SectionType.SECTION) {
        section = currentPage?.sections?.find((section: Section) => section.id === state.selectedSection?.id)
        if (section !== undefined) {
          section.images = section.images.filter((img: SectionImage) => img.slug !== image.slug)
        }
      }

      commit('SET_DRAFT', draft)
      commit('SET_CURRENT_PAGE', currentPage)

      if (section) {
        commit('SET_SELECTED_SECTION', section)
        DraftStateController.getInstance().removeImage(draft, section, image)?.then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      }
    })
  },
  setExternalConfiguration ({ state, commit }, id: number): void {
    const draft: Draft = cloneClassObject(state.draft as Draft)
    for (const property in draft.configuration) {
      if (property.includes('_configuration')) {
        if (draft.configuration[property].id === id) {
          commit('SET_SELECTED_EXTERNAL_CONFIGURATION', draft.configuration[property])
        }
      }
    }
  },
  setSelectedSection ({ commit }, section: Section) {
    commit('SET_SELECTED_SECTION', section)
  },
  setPreviewType ({ commit }, previewType: PreviewSizeType) {
    pushEvent('wbChangePreviewType')

    commit('SET_PREVIEW_TYPE', previewType)
  },
  setBusinessType ({ commit }, businessType: BusinessType) {
    commit('SET_BUSINESS_TYPE', businessType)
  },
  setWebsiteStyleModel ({ commit }, websiteStyleModel: WebsiteStyleModel) {
    commit('SET_WEBSITE_STYLE_MODEL', websiteStyleModel)
  }
}
