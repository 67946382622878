import api from '@/lib/calendesk-js-library/api/api'
import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { DomainSelect } from './types'
import Domain from '@/calendesk/models/DTO/Response/Domain'
import { plainToClass } from 'class-transformer'
import Draft from '@/calendesk/models/DTO/Response/Draft'

export const actions: ActionTree<DomainSelect, RootState> = {
  fetch ({ rootGetters, commit }, data: Record<string, any>): Promise<Domain[]> {
    return new Promise((resolve, reject) => {
      api.getDomains(data)
        .then(({ data }) => {
          const domains = plainToClass(Domain, data.data as Domain[])
          const draft: Draft | null = rootGetters['builder/getDraft']
          commit('SET_DOMAINS', domains)

          if (domains.length > 0 && draft) {
            const domain = domains.find((domain) => domain.draftUuid === draft.uuid)
            commit('SET_DEFAULT_DOMAIN', domain || null)
          } else {
            commit('SET_DEFAULT_DOMAIN', null)
          }
          resolve(domains)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  update ({ rootGetters, dispatch }, domain: Domain): Promise<Domain> {
    return new Promise((resolve, reject) => {
      const draft: Draft | null = rootGetters['builder/getDraft']

      api.updateDomain(domain.id, {
        domain: domain.domain,
        draft_uuid: draft?.uuid
      })
        .then(({ data }) => {
          dispatch('fetch').finally(() => {
            const domain = plainToClass(Domain, data)
            resolve(domain)
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  add ({ rootGetters, dispatch }, domain: string): Promise<Domain> {
    return new Promise((resolve, reject) => {
      const draft: Draft | null = rootGetters['builder/getDraft']

      api.addDomain({
        domain: domain,
        draft_uuid: draft?.uuid
      })
        .then(({ data }) => {
          dispatch('fetch').finally(() => {
            const domain = plainToClass(Domain, data)
            resolve(domain)
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  remove ({ dispatch }, id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      api.removeDomain(id)
        .then(() => {
          dispatch('fetch').finally(() => {
            resolve()
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
