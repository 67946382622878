import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import log from "@/lib/calendesk-js-library/tools/log";
import {
  getAccessToken,
  tokenOperations,
} from "@/lib/calendesk-js-library/tools/token";
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus";
import store from "@/store";

const resetApp = (message) => {
  log.error(message);
  const locale = localStorage.locale ?? "en";
  localStorage.clear();
  window.location.replace(`/${locale}`);
};

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 120000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
});

export const refreshAuthLogic = (failedRequest) =>
  refreshToken().then((tokenRefreshResponse) => {
    tokenOperations(tokenRefreshResponse.data);
    failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
    return Promise.resolve();
  });

httpClient.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;
  return request;
});

createAuthRefreshInterceptor(httpClient, refreshAuthLogic);

function refreshToken() {
  const data = {
    refresh_token: localStorage.getItem("refreshToken"),
  };
  return sendRequest("POST", "auth/token/refresh", data, false);
}

async function getCaptchaToken(action) {
  return new Promise((resolve) => {
    try {
      if (typeof grecaptcha === "object") {
        // eslint-disable-next-line no-undef
        grecaptcha.enterprise
          .execute(process.env.VUE_APP_RECAPTCHA_CLIENT_KEY, { action })
          .then((token) => {
            resolve(token);
          });
      } else {
        resolve(null);
      }
    } catch (e) {
      resolve(null);
    }
  });
}

export async function sendRequest(
  method,
  url,
  data = null,
  sendWithAuth = false,
  headers = {},
  otherOptions = {},
  captchaRequired = false,
  blobResponseType = false
) {
  if (sendWithAuth && !store.getters["setup/getTenant"]) {
    resetApp("Tenant unknown, API does not work without tenant!");
    return new Promise((resolve, reject) => {
      reject(new Error("Tenant unknown, API does not work without tenant!"));
    });
  }

  let captchaToken = null;
  if (captchaRequired) {
    captchaToken = await getCaptchaToken(url);
  }

  log.info("API sendRequest request: ", [
    store.getters["setup/getTenant"],
    method,
    url,
    data,
    headers,
    otherOptions,
    { captchaRequired },
  ]);

  const options = {
    method,
    url,
    params: method === "GET" ? data : null,
    data: method !== "GET" ? data : null,
    headers: {
      "X-Frontend-URL": window.location.href,
      "X-Tenant": store.getters["setup/getTenant"],
      ...headers,
      Authorization: `Bearer ${getAccessToken()}`,
    },
    ...otherOptions,
  };

  if (blobResponseType) {
    options.responseType = "blob";
  }

  if (captchaRequired && captchaToken) {
    options.headers["X-Captcha-Token"] = captchaToken;
  }

  return new Promise((resolve, reject) => {
    httpClient(options)
      .then((response) => {
        log.info("API sendRequest response: ", [
          store.getters["setup/getTenant"],
          method,
          url,
          data,
          response,
        ]);
        resolve(response);
      })
      .catch((error) => {
        const data =
          error && error.response && error.response.data
            ? JSON.stringify(error.response.data)
            : error;
        log.error("API sendRequest error: ", [url, data]);

        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.code
        ) {
          // Handle global codes

          if (error.response.data.code === "REQUIRED_CAPABILITY") {
            if (url === "admin/employees" && method === "POST") {
              EventBus.$emit("EMPLOYEE_LIMIT", data);
            } else {
              EventBus.$emit("REQUIRED_CAPABILITY", data);
            }
          }

          if (error.response.data.code === "REQUIRED_PERMISSION") {
            EventBus.$emit("REQUIRED_PERMISSION", data);
          }

          if (error.response.data.code === "NOTIFICATION_USAGE_NOT_AVAILABLE") {
            EventBus.$emit("NOTIFICATION_USAGE_NOT_AVAILABLE", data);
          }
        }

        if (url === "auth/token/refresh") {
          resetApp("Refresh token failed.");
        }
        reject(error);
      });
  });
}
