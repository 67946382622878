import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { SimpleStoreState } from './types'
import api from '@/lib/calendesk-js-library/api/api'
import { plainToClass } from 'class-transformer'
import SimpleStoreProduct from '@/calendesk/models/DTO/Response/SimpleStoreProduct'

export const actions: ActionTree<SimpleStoreState, RootState> = {
  fetch ({ commit }, data: Record<string, any>): Promise<SimpleStoreProduct[]> {
    return new Promise((resolve, reject) => {
      api.getAllSimpleStoreProducts(data)
        .then(({ data }) => {
          const products = plainToClass(SimpleStoreProduct, data.data as SimpleStoreProduct[])
          commit('SET_PRODUCTS', products)
          resolve(products)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
