import api from '@/lib/calendesk-js-library/api/api'
import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { EmployeesSelect } from './types'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import { plainToClass } from 'class-transformer'

export const actions: ActionTree<EmployeesSelect, RootState> = {
  fetch ({ commit }): Promise<Employee[]> {
    return new Promise((resolve, reject) => {
      api.employees({ is_public: true, limit: 9999 })
        .then(({ data }) => {
          const employees = plainToClass(Employee, data.data as Employee[])
          commit('SET_EMPLOYEES', employees)
          resolve(employees)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
