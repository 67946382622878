import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { CategoryState } from './types'
import Category from '@/calendesk/models/DTO/Response/Category'
import api from '@/lib/calendesk-js-library/api/api'
import { plainToClass } from 'class-transformer'
import Service from '@/calendesk/models/DTO/Response/Service'
export const actions: ActionTree<CategoryState, RootState> = {
  fetchCategoryAndServices ({ commit }, data: Record<string, any>): Promise<Category[]> {
    // This supposes to have always all categories&services.

    return new Promise((resolve, reject) => {
      api.categoriesAndServices(data)
        .then(({ data }) => {
          const categories = plainToClass(Category, data as Category[])
          commit('SET_CATEGORY_AND_SERVICES', categories)

          const services: Service[] = []

          categories.forEach((category) => {
            if (category.services != null) {
              services.push(...category.services)
            }
          })

          commit('service/SET_SERVICES', services, { root: true })

          resolve(categories)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
