import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { SubscriptionState } from './types'
import api from '@/lib/calendesk-js-library/api/api'
import { plainToClass } from 'class-transformer'
import Subscription from '@/calendesk/models/DTO/Response/Subscription'

export const actions: ActionTree<SubscriptionState, RootState> = {
  fetch ({ commit }, data: Record<string, any>): Promise<Subscription[]> {
    return new Promise((resolve, reject) => {
      api.getAllSubscriptions(data)
        .then(({ data }) => {
          const subscriptions = plainToClass(Subscription, data.data as Subscription[])
          commit('SET_SUBSCRIPTIONS', subscriptions)
          resolve(subscriptions)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
