import { ActionTree } from 'vuex'
import { SetupState } from './types'
import { RootState } from '../../types'
import { Route } from 'vue-router'
import api from '@/lib/calendesk-js-library/api/api'
import { tokenOperations } from '@/lib/calendesk-js-library/tools/token'
import vuetify from '@/plugins/vuetify'
import { plainToClass } from 'class-transformer'
import Configuration from '@/calendesk/models/DTO/Response/Configuration'
import moment from 'moment-timezone'
import AdminConfiguration from '@/calendesk/models/DTO/Response/AdminConfiguration'
import { Moment } from 'moment'
import TenantPlan from '@/calendesk/models/DTO/Response/TenantPlan'
import { url } from '@/lib/calendesk-js-library/filters/url'
import { setItem } from '@/lib/calendesk-js-library/tools/localStorageManager'

export const actions: ActionTree<SetupState, RootState> = {
  async init ({ commit }, to: Route): Promise<void> {
    return new Promise((resolve, reject) => {
      if (to.params.draft_id) {
        localStorage.setItem('draft_id', to.params.draft_id)
      }

      api.createAuthTokenWithSessionId({
        session_id: to.query.session_id
      })
        ?.then(({ data }) => {
          tokenOperations(data)

          if (data.issuer) {
            commit('auth/SET_CD_ADMIN_SESSION_ISSUER', data.issuer, { root: true })
          }

          resolve()
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  setPreloader ({ commit }, preloader: boolean) {
    commit('SET_PRELOADER', preloader)
  },
  setReferrer ({ commit }, referrer: string | null) {
    if (referrer && url(referrer)) {
      commit('SET_REFERRER', referrer)
    }
  },
  setTenant ({ commit }, tenant: string | null) {
    commit('SET_TENANT', tenant)
    setItem('tenant', tenant, false, false)
  },
  fetchConfiguration ({ commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      api.configuration().then(({ data }) => {
        // Configuration has to be stored as it comes from the server, there is global func $config shared by lib.
        commit('SET_CONFIGURATION', data)

        const configuration = plainToClass(Configuration, data, { excludeExtraneousValues: true })
        commit('SET_APP_CONFIGURATION', configuration)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  fetchAdminConfiguration ({ commit }): Promise<AdminConfiguration> {
    return new Promise((resolve, reject) => {
      api.adminConfiguration().then(({ data }) => {
        const configuration = plainToClass(AdminConfiguration, data)
        commit('SET_ADMIN_CONFIGURATION', configuration)
        resolve(configuration)
      }).catch(error => {
        reject(error)
      })
    })
  },
  fetchCurrentTenantPlan ({ commit }): Promise<TenantPlan | null> {
    return new Promise((resolve, reject) => {
      api.getCurrentTenantPlan().then(({ data }) => {
        if (data && data.length > 0) {
          const tenantPlan = plainToClass(TenantPlan, data[0])
          commit('SET_CURRENT_TENANT_PLAN', tenantPlan)
          commit('SET_CURRENT_TENANT_PLAN_SLUG', tenantPlan.slug)
          resolve(tenantPlan)
        } else {
          resolve(null)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateConfiguration ({ dispatch }, payload): Promise<void> {
    return new Promise((resolve, reject) => {
      api.updateConfiguration(payload).then(() => {
        dispatch('fetchConfiguration').then(() => {
          resolve()
        })
      }).catch(error => {
        reject(error)
      })
    })
  },
  fetchRegulations ({ commit }): Promise<string> {
    return new Promise((resolve, reject) => {
      api.configuration('regulations')
        ?.then(({ data }) => {
          commit('SET_REGULATIONS', data.regulations)
          resolve(data.regulations)
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  fetchPrivacyPolicy ({ commit }): Promise<string> {
    return new Promise((resolve, reject) => {
      api.configuration('privacy_policy')
        ?.then(({ data }) => {
          commit('SET_PRIVACY_POLICY', data.privacy_policy)
          resolve(data.privacy_policy)
        })
        ?.catch(error => {
          reject(error)
        })
    })
  },
  async updateLanguage ({ commit, state }, language: string) {
    if (language !== localStorage.getItem('locale') || !state.translations) {
      let locale = language ? language.toLowerCase() : 'en'
      const supportedLocales = [
        'pl', 'en', 'es', 'de'
      ]

      if (!supportedLocales.includes(language)) {
        locale = 'en'
      }

      const translations = {}
      const files = [
        '1',
        '2',
        '3'
      ]

      for (const range of files) {
        const languageStrings = await import('@/lib/calendesk-js-library/lang/' + locale + '_' + range)
        Object.assign(translations, languageStrings.default)
      }

      commit('SET_TRANSLATIONS', translations)

      vuetify.framework.lang.current = locale
      localStorage.setItem('locale', locale)
      document.documentElement.lang = language
      moment.locale(locale)
    }
  },
  setVersionCheckedAt ({ commit }, date: Moment | null) {
    commit('SET_VERSION_CHECKED_AT', date)
  }
}
