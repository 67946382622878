import { render, staticRenderFns } from "./CSelectedSection.vue?vue&type=template&id=67785de0&scoped=true&"
import script from "./CSelectedSection.vue?vue&type=script&lang=ts&"
export * from "./CSelectedSection.vue?vue&type=script&lang=ts&"
import style0 from "./CSelectedSection.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CSelectedSection.vue?vue&type=style&index=1&id=67785de0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67785de0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCol,VContainer,VFadeTransition,VHover,VIcon,VOverlay,VRow,VTooltip})
