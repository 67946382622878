import { sendRequest } from "@/lib/calendesk-js-library/api/api-client";

const api = {
  healthy() {
    return sendRequest("GET", "healthy/tenant");
  },

  healthyCentral() {
    return sendRequest("GET", "healthy/central");
  },

  configuration(elements = "all") {
    return sendRequest("GET", `settings/${elements}`);
  },

  adminConfiguration() {
    return sendRequest("GET", "settings/admin");
  },

  updateConfiguration(data) {
    return sendRequest("PATCH", "admin/settings", data, true);
  },

  generateRegulations(data) {
    return sendRequest("POST", "admin/regulations/generate", data, true);
  },

  stats() {
    return sendRequest("GET", "admin/stats", null, true);
  },

  usersData() {
    return sendRequest("GET", "admin/user", null, true);
  },

  calendarBookings(data) {
    return sendRequest("GET", "admin/bookings/calendar", data, true);
  },

  booking(bookingId) {
    return sendRequest("GET", `admin/bookings/${bookingId}`, null, true);
  },

  unavailableBookingSlot(unavailableBookingSlotId) {
    return sendRequest(
      "GET",
      `admin/unavailable-booking-slots/${unavailableBookingSlotId}`,
      null,
      true
    );
  },

  groupBooking(groupId) {
    return sendRequest("GET", `admin/bookings/group/${groupId}`, null, true);
  },

  updateGroupBooking(data, groupId) {
    return sendRequest("PATCH", `admin/bookings/group/${groupId}`, data, true);
  },

  bookings(data) {
    return sendRequest("GET", "admin/bookings/v2", data, true);
  },

  events(data) {
    return sendRequest("GET", "admin/events", data, true);
  },

  groupBookings(data) {
    return sendRequest("GET", "admin/bookings/group", data, true);
  },

  generateFileRecords(category, data) {
    let url = null;
    const type = data.type;

    if (category === "events") {
      url = "admin/events/generate/" + data.type;
    } else if (category === "unavailable_booking_slots") {
      url = "admin/unavailable-booking-slots/generate/" + data.type;
    } else if (category === "bookings") {
      url = "admin/bookings/generate/" + data.type;
    } else if (category === "newsletter") {
      url = "admin/newsletter/generate/" + data.type;
    } else if (category === "users") {
      url = "admin/users/generate/" + data.type;
    } else if (category === "employees") {
      url = "admin/employees/generate/" + data.type;
    }

    const isBlob = type === "xlsx" || type === "ods" || type === "xls";

    return sendRequest("POST", url, data, true, false, {}, false, isBlob);
  },

  createBooking(data) {
    return sendRequest("POST", "admin/bookings", data, true);
  },

  createBookingPaymentTransaction(data) {
    return sendRequest("POST", "admin/payments/bookings", data, true);
  },

  deleteBookingPaymentTransaction(bookingId) {
    return sendRequest(
      "DELETE",
      `admin/payments/bookings/${bookingId}`,
      null,
      true
    );
  },

  updateBooking(data, id) {
    return sendRequest("PATCH", `admin/bookings/${id}`, data, true);
  },

  updateMultipleBookings(data) {
    return sendRequest("PATCH", "admin/bookings", data, true);
  },

  deleteMultipleBookings(data) {
    return sendRequest("DELETE", "admin/bookings", data, true);
  },

  deleteMultipleUnavailableSlotsBookings(data) {
    return sendRequest("DELETE", "admin/unavailable-booking-slots", data, true);
  },

  deleteBooking(id) {
    return sendRequest("DELETE", `admin/bookings/${id}`, null, true);
  },

  unavailableSlots(data) {
    return sendRequest("GET", "admin/unavailable-booking-slots", data, true);
  },

  categoriesAndServices(data) {
    return sendRequest("GET", "admin/v2/categories/services", data, true);
  },

  categories(data) {
    return sendRequest("GET", "admin/categories", data, true);
  },

  category(id) {
    return sendRequest("GET", `admin/categories/${id}`, null, true);
  },

  createCategory(data) {
    return sendRequest("POST", "admin/categories", data, true);
  },

  updateCategory(data, id) {
    return sendRequest("PATCH", `admin/categories/${id}`, data, true);
  },

  deleteCategory(id) {
    return sendRequest("DELETE", `admin/categories/${id}`, null, true);
  },
  service(id) {
    return sendRequest("GET", `admin/services/${id}`, null, true);
  },
  searchForServices(data) {
    return sendRequest("GET", `admin/v2/services/search`, data, true);
  },
  createService(data) {
    return sendRequest("POST", "admin/services", data, true);
  },
  services(data) {
    return sendRequest("GET", "admin/services", data, true);
  },
  servicesSearch(data) {
    return sendRequest("GET", "admin/v2/services/search", data, true);
  },
  updateService(data, id) {
    return sendRequest("PATCH", `admin/services/${id}`, data, true);
  },
  updateMultipleServices(data) {
    return sendRequest("PATCH", `admin/services`, data, true);
  },
  deleteService(id) {
    return sendRequest("DELETE", `admin/services/${id}`, null, true);
  },
  createServiceType(data) {
    return sendRequest("POST", `admin/services/${data.serviceId}`, data, true);
  },
  deleteServiceType({ serviceId, serviceTypeId }) {
    return sendRequest(
      "DELETE",
      `admin/services/${serviceId}/types/${serviceTypeId}`,
      null,
      true
    );
  },

  updateServiceType(data) {
    return sendRequest(
      "PATCH",
      `admin/services/${data.serviceId}/types/${data.serviceTypeId}`,
      {
        price: data.price,
        name: data.name,
        duration: data.duration,
      },
      true
    );
  },

  employees(data) {
    return sendRequest("GET", "admin/employees", data, true);
  },

  employee(id) {
    return sendRequest("GET", `admin/employees/${id}`, null, true);
  },

  createEmployee(data) {
    return sendRequest("POST", "admin/employees", data, true);
  },

  updateEmployee(data, id) {
    return sendRequest("PATCH", `admin/employees/${id}`, data, true);
  },

  updateOwnEmployee(data) {
    return sendRequest("PATCH", `admin/employee`, data, true);
  },

  deleteEmployee(id) {
    return sendRequest("DELETE", `admin/employees/${id}`, null, true);
  },

  updateMultipleEmployees(data) {
    return sendRequest("PATCH", "admin/employees", data, true);
  },

  deleteMultipleEmployees(data) {
    return sendRequest("DELETE", "admin/employees", data, true);
  },

  users(data) {
    return sendRequest("GET", "admin/v2/users", data, true);
  },

  usersSearch(data) {
    return sendRequest("GET", "admin/v2/users/search", data, true);
  },

  user(id) {
    return sendRequest("GET", `admin/users/${id}`, null, true);
  },

  createUser(data) {
    return sendRequest("POST", "admin/users", data, true);
  },

  updateUser(data, id) {
    return sendRequest("PATCH", `admin/users/${id}`, data, true);
  },

  updateOwnUser(data) {
    return sendRequest("PATCH", `admin/user`, data, true);
  },

  updateUserPreferences(data, id) {
    return sendRequest("PUT", `admin/users/${id}/preferences`, data, true);
  },

  updateMultipleUsers(data) {
    return sendRequest("PATCH", "admin/users", data, true);
  },

  deleteMultipleUsers(data) {
    return sendRequest("DELETE", "admin/users", data, true);
  },

  deleteUser(id) {
    return sendRequest("DELETE", `admin/users/${id}`, null, true);
  },

  tags(data) {
    return sendRequest("GET", "admin/tags", data, true);
  },

  createTag(data) {
    return sendRequest("POST", "admin/tags", data, true);
  },

  updateTag(data, id) {
    return sendRequest("PATCH", `admin/tags/${id}`, data, true);
  },

  deleteTag(id) {
    return sendRequest("DELETE", `admin/tags/${id}`, null, true);
  },

  groups(data) {
    return sendRequest("GET", "admin/groups", data, true);
  },

  createGroup(data) {
    return sendRequest("POST", "admin/groups", data, true);
  },

  updateGroup(data, id) {
    return sendRequest("PATCH", `admin/groups/${id}`, data, true);
  },

  deleteGroup(id) {
    return sendRequest("DELETE", `admin/groups/${id}`, null, true);
  },

  permissions(data) {
    return sendRequest("GET", "admin/permissions", data, true);
  },

  logs(data) {
    return sendRequest("GET", "admin/logs", data, true);
  },

  roles(data) {
    return sendRequest("GET", "admin/roles", data, true);
  },

  resources(data) {
    return sendRequest("GET", "admin/resources", data, true);
  },

  createResource(data) {
    return sendRequest("POST", "admin/resources", data, true);
  },

  updateResource(data, id) {
    return sendRequest("PATCH", `admin/resources/${id}`, data, true);
  },

  deleteResource(id) {
    return sendRequest("DELETE", `admin/resources/${id}`, null, true);
  },

  createUnavailableBookingSpot(data) {
    return sendRequest("POST", "admin/unavailable-booking-slots", data, true);
  },

  updateUnavailableBookingSpot(data, id) {
    return sendRequest(
      "PATCH",
      `admin/unavailable-booking-slots/${id}`,
      data,
      true
    );
  },

  deleteUnavailableBookingSpot(id) {
    return sendRequest(
      "DELETE",
      `admin/unavailable-booking-slots/${id}`,
      null,
      true
    );
  },

  getLocale() {
    return sendRequest("GET", "locale");
  },

  register(data, headers) {
    return sendRequest("POST", "tenants", data, false, headers, {}, true);
  },

  login(data) {
    return sendRequest(
      "POST",
      "admin/auth/login",
      data,
      false,
      {},
      { skipAuthRefresh: true },
      true
    );
  },

  logout() {
    return sendRequest(
      "POST",
      "auth/logout",
      null,
      true,
      {},
      { skipAuthRefresh: true }
    );
  },

  updateUsersData(data) {
    return sendRequest("PATCH", "user", data, true);
  },

  uploadAvatar(data) {
    return sendRequest("POST", "user/upload/avatar", data, true, {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/x-www-form-urlencoded",
    });
  },

  uploadAvatarAdmin(data, id) {
    return sendRequest("POST", `admin/users/${id}/upload/picture`, data, true, {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/x-www-form-urlencoded",
    });
  },

  forgottenPassword(email) {
    return sendRequest(
      "GET",
      `admin/auth/login/${email}/remind-password`,
      null,
      false
    );
  },

  resetPassword(data, token) {
    return sendRequest(
      "PUT",
      `auth/login/reset-password/${token}`,
      data,
      false
    );
  },

  resendActivateEmail(email) {
    return sendRequest("GET", `auth/signup/${email}/resend-activation-link/1`);
  },

  activateAccount(token) {
    return sendRequest("GET", `auth/signup/activate/${token}`);
  },

  companyData({ taxCountryISOCode, taxNumber }) {
    return sendRequest(
      "GET",
      `admin/company/${taxCountryISOCode}/${taxNumber}`,
      null,
      true
    );
  },

  updateTranslations(language, type, data) {
    return sendRequest(
      "PUT",
      `admin/translations/${language}/${type}`,
      { translations: data },
      true
    );
  },

  getAssetsUser(data) {
    return sendRequest("GET", "admin/user-images", data, true);
  },

  uploadUserImage(data, cancelToken, onUploadProgress) {
    return sendRequest(
      "POST",
      "admin/user-images",
      data,
      true,
      {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      { cancelToken, onUploadProgress }
    );
  },

  updateUserImage(id, data) {
    return sendRequest("PATCH", `admin/user-images/${id}`, data, true);
  },

  removeAssetUser(id) {
    return sendRequest("DELETE", `admin/user-images/${id}`, null, true);
  },

  getWebDrafts(data) {
    return sendRequest("GET", "admin/editor/web/drafts", data, true);
  },

  getWebDraft(id) {
    return sendRequest("GET", `admin/editor/web/drafts/${id}`, null, true);
  },

  updateWebDraft(id, data) {
    return sendRequest("PATCH", `admin/editor/web/drafts/${id}`, data, true);
  },

  removeWebDraft(id) {
    return sendRequest("DELETE", `admin/editor/web/drafts/${id}`, null, true);
  },

  getMobileTemplates() {
    return sendRequest("GET", "admin/editor/mobile/template", null, true);
  },

  getMobileDrafts(data) {
    return sendRequest("GET", "admin/editor/mobile/drafts", data, true);
  },

  createMobileDraft(data) {
    return sendRequest("POST", "admin/editor/mobile/drafts", data, true);
  },

  getMobileDraft(id) {
    return sendRequest("GET", `admin/editor/mobile/drafts/${id}`, null, true);
  },

  updateMobileDraft(id, data) {
    return sendRequest("PATCH", `admin/editor/mobile/drafts/${id}`, data, true);
  },

  publishMobileDraft(id) {
    return sendRequest(
      "POST",
      `admin/editor/mobile/drafts/${id}/apply`,
      null,
      true
    );
  },

  disableMobileDraft(id) {
    return sendRequest(
      "POST",
      `admin/editor/mobile/drafts/${id}/disable`,
      null,
      true
    );
  },

  removeMobileDraft(id) {
    return sendRequest(
      "DELETE",
      `admin/editor/mobile/drafts/${id}`,
      null,
      true
    );
  },

  getDomains(data) {
    return sendRequest("GET", "admin/domains", data, true);
  },

  getAvailableDomains() {
    return sendRequest("GET", "admin/domains/available", null, true);
  },

  addDomain(data) {
    return sendRequest("POST", "admin/domains", data, true);
  },

  updateDomain(id, data) {
    return sendRequest("PATCH", `admin/domains/${id}`, data, true);
  },

  removeDomain(id) {
    return sendRequest("DELETE", `admin/domains/${id}`, null, true);
  },

  searchForTenant(data) {
    return sendRequest("GET", "tenants", data);
  },

  getPlaces(data) {
    return sendRequest("GET", "admin/places", data, true);
  },

  getPlaceDetails(id) {
    return sendRequest("GET", `admin/places/${id}`, null, true);
  },

  getCompanyWorkingHours() {
    return sendRequest("GET", "admin/company-working-hours", null, true);
  },

  updateCompanyWorkingHours(data) {
    return sendRequest("PATCH", "admin/company-working-hours", data, true);
  },

  getCurrentTenantPlan() {
    return sendRequest("GET", "admin/tenant-plans/current", null, true);
  },

  getNotificationsUsage() {
    return sendRequest(
      "GET",
      "admin/tenant-plans/notifications/usage",
      null,
      true
    );
  },

  updatePlanTrial(data) {
    return sendRequest("PUT", "admin/tenant-plans/trial", data, true);
  },

  updateTenantPackagePreferences(data) {
    return sendRequest(
      "PATCH",
      "admin/tenant-packages/config/preferences",
      data,
      true
    );
  },

  updateTenantPackageConfiguration(data) {
    return sendRequest("PATCH", "admin/tenant-packages/config", data, true);
  },

  getPlans() {
    return sendRequest("GET", "admin/plans", null, true);
  },

  getDefaultPaymentMethod() {
    return sendRequest("GET", "admin/payment-method/default", null, true);
  },

  deletePaymentMethods() {
    return sendRequest("DELETE", "admin/payment-method/all", null, true);
  },

  getDefaultPaymentMethodInvokeToken(data) {
    return sendRequest("GET", "admin/payment-method/invoke", data, true);
  },

  getTenantsInvoiceData() {
    return sendRequest("GET", "admin/tenants/invoice", null, true);
  },

  getInvoices(data) {
    return sendRequest("GET", "admin/invoices", data, true);
  },

  updateTenantsInvoiceData(data) {
    return sendRequest("PATCH", "admin/tenants/invoice", data, true);
  },

  setupDefaultPaymentMethod(data) {
    return sendRequest("POST", "admin/payment-method/setup", data, true);
  },

  verifyPromoCode(code, period) {
    return sendRequest(
      "GET",
      `admin/promo-code/verify/${code}/${period}`,
      null,
      true
    );
  },

  subscribePlan(data) {
    return sendRequest("POST", "admin/subscribe", data, true);
  },

  retryPastDueSubscriptionPayment(data) {
    return sendRequest(
      "POST",
      "admin/subscription/invoices/past-due/pay",
      data,
      true
    );
  },

  getPastDueSubscriptionInvoice(data) {
    return sendRequest(
      "GET",
      "admin/subscription/invoices/past-due",
      data,
      true
    );
  },

  cancelSubscription() {
    return sendRequest("POST", "admin/subscription/cancel", null, true);
  },

  downloadInvoice(invoiceId) {
    return sendRequest(
      "GET",
      `admin/invoices/fakturownia/pdf/${invoiceId}`,
      null,
      true,
      {},
      {},
      false,
      true
    );
  },

  packagesPurchase(data) {
    return sendRequest("POST", "admin/packages/purchase", data, true);
  },

  sendEmailWithClientMobileApp(data) {
    return sendRequest("POST", "admin/editor/mobile/email/send", data, true);
  },

  sendSmsWithClientMobileApp(data) {
    return sendRequest("POST", "admin/editor/mobile/sms/send", data, true);
  },

  getStripeAccount(data) {
    return sendRequest("GET", "admin/stripe/connect/account", data, true);
  },

  createStripeAccount(data) {
    return sendRequest("POST", "admin/stripe/connect/account", data, true);
  },

  deleteStripeAccount() {
    return sendRequest("DELETE", "admin/stripe/connect/account", null, true);
  },

  getStripeConnectProducts(data) {
    return sendRequest("GET", "admin/stripe/connect/products", data, true);
  },

  createStripeConnectProduct(data) {
    return sendRequest("POST", "admin/stripe/connect/products", data, true);
  },

  createStripeConnectProductPrice(productId, data) {
    return sendRequest(
      "POST",
      `admin/stripe/connect/products/${productId}/prices`,
      data,
      true
    );
  },

  getPaymentsBookings(data) {
    return sendRequest("GET", "admin/payments/bookings", data, true);
  },

  getTenantByEmail(email) {
    return sendRequest("GET", "tenants/email", email);
  },

  createSessionId() {
    return sendRequest("POST", "admin/quick-session", null, true);
  },

  createAuthTokenWithSessionId(sessionId) {
    return sendRequest("POST", "admin/quick-session/token", sessionId);
  },

  getWb2Draft(id) {
    return sendRequest(
      "GET",
      `admin/website/builder/v2/drafts/${id}`,
      null,
      true
    );
  },

  updateWb2Draft(draftId, data) {
    return sendRequest(
      "PATCH",
      `admin/website/builder/v2/drafts/${draftId}`,
      data,
      true
    );
  },

  publishWb2Draft(id) {
    return sendRequest(
      "POST",
      `admin/website/builder/v2/drafts/${id}/apply`,
      null,
      true
    );
  },

  disableWb2Draft(id) {
    return sendRequest(
      "POST",
      `admin/website/builder/v2/drafts/${id}/disable`,
      null,
      true
    );
  },

  setDraftDomainAsMain(id) {
    return sendRequest(
      "POST",
      `admin/website/builder/v2/drafts/${id}/main-domain`,
      null,
      true
    );
  },

  createWb2DraftPage(draftId, data) {
    return sendRequest(
      "POST",
      `admin/website/builder/v2/drafts/${draftId}/pages`,
      data,
      true
    );
  },

  updateWb2DraftSection(draftId, sectionId, data) {
    return sendRequest(
      "PATCH",
      `admin/website/builder/v2/drafts/${draftId}/sections/${sectionId}`,
      data,
      true
    );
  },

  updateWb2DraftPage(draftId, pageId, data) {
    return sendRequest(
      "PATCH",
      `admin/website/builder/v2/drafts/${draftId}/pages/${pageId}`,
      data,
      true
    );
  },

  removeWb2DraftPage(draftId, pageId) {
    return sendRequest(
      "DELETE",
      `admin/website/builder/v2/drafts/${draftId}/pages/${pageId}`,
      null,
      true
    );
  },

  removeWb2DraftSection(draftId, sectionId) {
    return sendRequest(
      "DELETE",
      `admin/website/builder/v2/drafts/${draftId}/sections/${sectionId}`,
      null,
      true
    );
  },

  createWb2DraftSection(draftId, data) {
    return sendRequest(
      "POST",
      `admin/website/builder/v2/drafts/${draftId}/sections`,
      data,
      true
    );
  },

  updateWb2DraftSectionImage(draftId, sectionID, data) {
    return sendRequest(
      "PUT",
      `admin/website/builder/v2/drafts/${draftId}/sections/${sectionID}/images`,
      data,
      true
    );
  },

  getNotificationTemplates() {
    return sendRequest("GET", "admin/notifications/templates", null, true);
  },

  getNotificationTemplateTypes() {
    return sendRequest(
      "GET",
      "admin/notifications/templates/types",
      null,
      true
    );
  },

  createNotificationTemplate(data) {
    return sendRequest("POST", "admin/notifications/templates", data, true);
  },

  updateNotificationTemplate(templateId, data) {
    return sendRequest(
      "PATCH",
      `admin/notifications/templates/${templateId}`,
      data,
      true
    );
  },

  deleteNotificationTemplate(templateId) {
    return sendRequest(
      "DELETE",
      `admin/notifications/templates/${templateId}`,
      null,
      true
    );
  },

  removeWb2DraftSectionImage(draftId, sectionID, slug) {
    return sendRequest(
      "DELETE",
      `admin/website/builder/v2/drafts/${draftId}/sections/${sectionID}/images/${slug}`,
      null,
      true
    );
  },

  createWb2Draft(data) {
    return sendRequest("POST", "admin/website/builder/v2/drafts", data, true);
  },

  removeWb2Draft(id) {
    return sendRequest(
      "DELETE",
      `admin/website/builder/v2/drafts/${id}`,
      null,
      true
    );
  },

  searchForUnsplashPhotos(data) {
    return sendRequest("GET", "admin/photos/unsplash/search", data, true);
  },

  selectUnsplashPhoto(id) {
    return sendRequest(
      "GET",
      `admin/photos/unsplash/select?id=${id}`,
      null,
      true
    );
  },

  getAllNewsletterRecords(data) {
    return sendRequest("GET", "admin/newsletter", data, true);
  },

  getSingleNewsletterById(id) {
    return sendRequest("GET", `admin/newsletter/${id}`, null, true);
  },

  createNewsletter(data) {
    return sendRequest("POST", "admin/newsletter", data, true);
  },

  updateNewsletter(id, data) {
    return sendRequest("PATCH", `admin/newsletter/${id}`, data, true);
  },

  deleteMultipleNewsletterRecords(data) {
    return sendRequest("DELETE", "admin/newsletter", data, true);
  },

  getCustomerNotifications(data) {
    return sendRequest("GET", "admin/customer-notifications", data, true);
  },

  createCustomerNotification(data) {
    return sendRequest("POST", "admin/customer-notifications", data, true);
  },

  createGoogleCalendarAuthUrl(data) {
    return sendRequest("POST", "admin/google/calendar/auth-url", data, true);
  },

  createOutlookCalendarAuthUrl(data) {
    return sendRequest("POST", "admin/microsoft/outlook/auth-url", data, true);
  },

  createZoomAuthUrl(data) {
    return sendRequest("POST", "admin/zoom/auth-url", data, true);
  },

  enableGoogleCalendarForEmployee(data) {
    return sendRequest(
      "POST",
      "admin/google/calendar/employees/enable",
      data,
      true
    );
  },

  enableOutlookCalendarForEmployee(data) {
    return sendRequest(
      "POST",
      "admin/microsoft/outlook/employees/enable",
      data,
      true
    );
  },

  getListOfGoogleCalendars() {
    return sendRequest(
      "GET",
      "admin/google/calendar/employees/list",
      null,
      true
    );
  },

  getListOfOutlookCalendars() {
    return sendRequest(
      "GET",
      "admin/microsoft/outlook/employees/list",
      null,
      true
    );
  },

  pushEventsToGoogleCalendars() {
    return sendRequest(
      "POST",
      "admin/google/calendar/employees/push-events",
      null,
      true
    );
  },

  pushEventsToOutlookCalendars() {
    return sendRequest(
      "POST",
      "admin/microsoft/outlook/employees/push-events",
      null,
      true
    );
  },

  updateWatchedEmployeeGoogleCalendars(data) {
    return sendRequest(
      "PUT",
      "admin/google/calendar/employees/watched",
      data,
      true
    );
  },

  updateWatchedEmployeeOutlookCalendars(data) {
    return sendRequest(
      "PUT",
      "admin/microsoft/outlook/employees/watched",
      data,
      true
    );
  },

  getWatchedEmployeeGoogleCalendars() {
    return sendRequest(
      "GET",
      "admin/google/calendar/employees/watched/list",
      null,
      true
    );
  },

  getWatchedEmployeeOutlookCalendars() {
    return sendRequest(
      "GET",
      "admin/microsoft/outlook/employees/watched/list",
      null,
      true
    );
  },

  enableZoomForEmployee(data) {
    return sendRequest("POST", "admin/zoom/employees/enable", data, true);
  },

  disableGoogleCalendarForEmployee(data) {
    return sendRequest(
      "POST",
      "admin/google/calendar/employees/disable",
      data,
      true
    );
  },

  disableOutlookCalendarForEmployee(data) {
    return sendRequest(
      "POST",
      "admin/microsoft/outlook/employees/disable",
      data,
      true
    );
  },

  disableZoomorEmployee(data) {
    return sendRequest("POST", "admin/zoom/employees/disable", data, true);
  },

  getAllSubscriptions(data) {
    return sendRequest("GET", "admin/subscriptions", data, true);
  },

  getSubscription(id) {
    return sendRequest("GET", "admin/subscriptions/" + id, null, true);
  },

  getAllUserSubscriptions(data) {
    return sendRequest("GET", "admin/v2/users/subscriptions", data, true);
  },

  subscriptionsSearch(data) {
    return sendRequest("GET", "admin/subscriptions/search", data, true);
  },

  createUserSubscription(data) {
    return sendRequest("POST", `admin/users/subscriptions/`, data, true);
  },

  cancelUserSubscription(data) {
    return sendRequest(
      "POST",
      `admin/users/subscriptions/${data.id}/cancel`,
      data,
      true
    );
  },

  deleteUserSubscription(subscriptionId) {
    return sendRequest(
      "DELETE",
      `admin/users/subscriptions/${subscriptionId}`,
      null,
      true
    );
  },

  getAllUserSubscriptionPaymentTransactions(data) {
    return sendRequest(
      "GET",
      "admin/users/subscriptions/transactions",
      data,
      true
    );
  },

  createUserSubscriptionPaymentTransaction(data) {
    return sendRequest(
      "POST",
      `admin/users/subscriptions/` +
        data.user_subscription_id +
        `/transactions`,
      data,
      true
    );
  },

  deleteUserSubscriptionPaymentTransaction(
    userSubscriptionId,
    userSubscriptionPaymentTransactionId
  ) {
    return sendRequest(
      "DELETE",
      `admin/users/subscriptions/` +
        userSubscriptionId +
        `/transactions/` +
        userSubscriptionPaymentTransactionId,
      null,
      true
    );
  },

  createSubscription(data) {
    return sendRequest("POST", "admin/subscriptions", data, true);
  },

  updateSubscription(subscriptionId, data) {
    return sendRequest(
      "PUT",
      `admin/subscriptions/${subscriptionId}`,
      data,
      true
    );
  },

  deleteSubscription(subscriptionId) {
    return sendRequest(
      "DELETE",
      `admin/subscriptions/${subscriptionId}`,
      null,
      true
    );
  },

  getAllSimpleStoreProducts(data) {
    return sendRequest("GET", "admin/simple-store/product", data, true);
  },

  getAllSimpleStoreProductTransactions(data) {
    return sendRequest(
      "GET",
      "admin/simple-store/product/transaction",
      data,
      true
    );
  },

  createSimpleStoreProduct(data) {
    return sendRequest("POST", "admin/simple-store/product", data, true);
  },

  createSimpleStoreProductPaymentTransaction(data) {
    return sendRequest(
      "POST",
      "admin/simple-store/product/transaction",
      data,
      true
    );
  },

  deleteSimpleStoreProductPaymentTransaction(transactionId) {
    return sendRequest(
      "DELETE",
      `admin/simple-store/product/transaction/${transactionId}`,
      null,
      true
    );
  },

  deleteSimpleStoreProduct(productId) {
    return sendRequest(
      "DELETE",
      `admin/simple-store/product/${productId}`,
      null,
      true
    );
  },

  updateSimpleStoreProduct(productId, data) {
    return sendRequest(
      "PUT",
      `admin/simple-store/product/${productId}`,
      data,
      true
    );
  },

  validateFakturowniaSettings(data) {
    return sendRequest("POST", "admin/fakturownia/validate", data, true);
  },

  getApiKey(data) {
    return sendRequest("GET", "admin/api/key", data, true);
  },

  createApiKey(data) {
    return sendRequest("POST", "admin/api/key", data, true);
  },

  deleteApiKey(data) {
    return sendRequest("DELETE", "admin/api/key", data, true);
  },

  getAvailabilitySchedules(data) {
    return sendRequest("GET", "admin/availability-schedules", data, true);
  },

  createAvailabilitySchedule(data) {
    return sendRequest("POST", "admin/availability-schedules", data, true);
  },

  updateAvailabilitySchedule(id, data) {
    return sendRequest("PUT", `admin/availability-schedules/${id}`, data, true);
  },

  deleteAvailabilitySchedule(id) {
    return sendRequest(
      "DELETE",
      `admin/availability-schedules/${id}`,
      null,
      true
    );
  },

  getAvailableBookingSlots(data) {
    return sendRequest("GET", "available-slots", data);
  },

  getLocations(data) {
    return sendRequest("GET", "admin/location", data, true);
  },
  createLocation(data) {
    return sendRequest("POST", "admin/location", data, true);
  },
  updateLocation(data, locationId) {
    return sendRequest("PATCH", `admin/location/${locationId}`, data, true);
  },
  deleteLocation(locationId) {
    return sendRequest("DELETE", `admin/location/${locationId}`, null, true);
  },
};

export default api;
